import { Component } from '@angular/core';
import { ILocationForm } from '@desquare/interfaces';
import { LocationFormComponent } from '../location-form/location-form.component';
import { FormDialogComponent } from '@desquare/components/common/src/modals/form-dialog.component';

@Component({
  standalone: true,
  imports: [FormDialogComponent, LocationFormComponent],
  selector: 'app-create-location-dialog',
  templateUrl: './create-location-dialog.component.html',
  styleUrls: ['./create-location-dialog.component.scss'],
})
export class CreateLocationDialogComponent {
  isFormValid = false;
  isFormPristine = true;
  isCreateLocation = true;
  values?: ILocationForm;
}
