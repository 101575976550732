import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@designage/gql';
import { IDatatablePageChangeArgs } from '@desquare/interfaces';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { SubSink } from 'subsink';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { FormDialogComponent } from '@desquare/components/common/src/modals/form-dialog.component';

@Component({
  standalone: true,
  imports: [
    FormDialogComponent,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  selector: 'app-duplicate-location-name-dialog',
  templateUrl: './duplicate-location-name-dialog.component.html',
  styleUrls: ['./duplicate-location-name-dialog.component.scss'],
})
export class DuplicateLocationNameDialogComponent implements OnInit, OnDestroy {
  private subs = new SubSink();

  publishForm!: FormGroup;
  duplicateLocations: Location[] = [];
  locationName!: string;
  name!: string;

  total = 0;
  page = 1;
  pageSize = 10;

  constructor(public modal: NgbActiveModal, private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.initVariables();
    this.initForm();
    this.setFormState();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  initForm() {
    this.publishForm = this.formBuilder.group({
      name: [null],
    });
  }

  initVariables() {
    this.total = this.duplicateLocations.length;

    // this.columns = [
    //   {
    //     prop: 'streetAddress1',
    //     name: 'STREET_ADDRESS1',
    //   },
    //   {
    //     prop: 'streetAddress2',
    //     name: 'STREET_ADDRESS2',
    //   },
    //   {
    //     prop: 'zip',
    //     name: 'ZIP',
    //   },
    //   {
    //     prop: 'country',
    //     name: 'COUNTRY',
    //   },
    //   {
    //     prop: 'city',
    //     name: 'CITY',
    //   },
    //   {
    //     prop: 'phoneNumber',
    //     name: 'PHONE_NUMBER',
    //   },
    //   {
    //     prop: 'region',
    //     name: 'REGION',
    //   },
    // ];
  }

  onPageChange({ offset }: IDatatablePageChangeArgs) {
    this.page = offset;
  }

  setFormState() {
    this.subs.sink = this.publishForm.valueChanges.subscribe(() => {
      this.name = this.publishForm.controls.name.value;
    });
  }
}
