import {
  Component,
  OnInit,
  Input,
  Output,
  AfterViewInit,
  EventEmitter,
  OnDestroy,
  SimpleChanges,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { SubSink } from 'subsink';
import { ILocationForm, ICountry } from '@desquare/interfaces';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormsModule,
  FormGroupDirective,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  Maybe,
  GetLocationForLocationManagePageQuery,
  ProfileLocationsFragment,
  GetProfileLocationsGQL,
} from '@designage/gql';
import {
  getData as getCountryListData,
  getCode as getCountryListCode,
} from 'country-list';
import * as mapboxgl from 'mapbox-gl';
import * as _ from 'lodash';
import { environment } from '@desquare/environments';
import { MapboxService } from '@desquare/services';
import { IMapSettings } from '@desquare/interfaces';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { localStorageKeys } from '@desquare/constants';
import { LocalStorageService } from 'ngx-webstorage';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, TranslateModule],
  selector: 'app-location-form',
  templateUrl: './location-form.component.html',
  styleUrls: ['./location-form.component.scss'],
})
export class LocationFormComponent implements OnInit, AfterViewInit, OnDestroy {
  private subs = new SubSink();
  @ViewChild('mapboxContainer') mapboxContainer!: ElementRef;
  @Output() submitted = new EventEmitter<ILocationForm>();
  @Output() values = new EventEmitter<ILocationForm>();
  @Output() valid = new EventEmitter<boolean>();
  @Output() pristine = new EventEmitter<boolean>();
  @Output() loading = new EventEmitter<boolean>();
  @Output() loaderMessage = new EventEmitter<string>();
  @Input() location!: Maybe<GetLocationForLocationManagePageQuery['location']>;
  @Input() formId = 'locationForm';
  @Input() showMapOnly = false;
  @Input() showLocationName = true;
  @Input() showLocationSearch = true;
  @Input() isCreate = false;
  @Input() locationName!: string;

  @Input() parentFormGroup?: FormGroup;

  profileLocations: ProfileLocationsFragment[] = [];
  selectedLocation!: ProfileLocationsFragment;
  locationForm!: FormGroup;
  countries!: ICountry[];
  map!: mapboxgl.Map;
  geocoder = new MapboxGeocoder({
    accessToken: environment.mapbox.accessToken,
    language: 'en-EN',
    mapboxgl,
  });
  marker = new mapboxgl.Marker({
    color: '#FFFFFF',
    draggable: false,
  });
  geocoderInputString!: string;
  mapboxResponse!: any;
  coordinates!: mapboxgl.Point;
  mapSettings!: IMapSettings;
  // mapSettings: IMapSettings = {
  //   container: 'map1',
  //   style: this.mapboxService.DarkStyle,
  //   center: this.mapboxService.DefaultMapCoords,
  //   zoom: 15,
  // };

  editingLocation!: boolean;
  isDuplicateName!: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private mapboxService: MapboxService,
    private getProfileLocationGQL: GetProfileLocationsGQL,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit() {
    this.initVariables();
    this.initForm();
    this.setFormStates();
    this.initSubscriptions();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.map?.remove();
    // console.log('COMPONENT DESTROYED');
  }

  // note 02m-20d-2023y: this form needs a big refactor, we should avoid
  // using ngOnChanges since it is too sensitive, this can cause
  // infinite loops if not handled properly
  ngOnChanges(changes: SimpleChanges) {
    if (!this.locationForm) {
      this.initForm();
    }
    if (changes.location && changes.location.currentValue && this.location) {
      // potential loop with setFormStates()
      this.setControlValues();
    }
    if (!this.location) {
      this.initVariables();
      this.initForm();
      this.setFormStates();
      this.initSubscriptions();
    }
    if (changes.showLocationSearch?.currentValue) {
      this.initMap();
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.initMap(), 500);
  }

  initVariables() {
    this.editingLocation = false;
    this.isDuplicateName = false;
    this.coordinates = this.mapboxService.coordsToPoint(
      this.mapboxService.DefaultMapCoords
    );
    this.valid.emit(false);
    this.pristine.emit(true);
    this.countries = getCountryListData();
    this.loading.emit(false);
  }

  initForm() {
    const FORM_GROUP_NAME = 'location';

    this.locationForm =
      (this.parentFormGroup?.controls[FORM_GROUP_NAME] as FormGroup) ??
      this.formBuilder.group({
        id: this.location ? this.location.id : null,
        name: [
          this.parentFormGroup?.controls.name?.value ?? this.locationName,
          Validators.required,
        ],
        streetAddress1: [null, Validators.required],
        streetAddress2: [null],
        zip: [null, Validators.required],
        phoneNumber: [null],
        country: [null, Validators.required],
        region: [null],
        city: [null, Validators.required],
        coordinates: this.coordinates,
      });

    if (this.locationForm.controls.id?.value === null) {
      this.locationForm.removeControl('id');
    }

    this.setControlValues();

    if (this.parentFormGroup) {
      this.parentFormGroup.addControl(FORM_GROUP_NAME, this.locationForm);
    }
  }

  setFormStates() {
    // potential loop with ngOnChanges()
    this.subs.sink = this.locationForm.valueChanges.subscribe(() => {
      this.isDuplicateName = this.hasDuplicateName(
        this.locationForm.controls.name.value
      );
      this.valid.emit(this.locationForm.valid && !this.isDuplicateName);
      this.pristine.emit(false);
      this.values.emit(this.locationForm.value);
    });
  }

  initSubscriptions() {
    const profileId = this.localStorageService.retrieve(
      localStorageKeys.ACTIVE_PROFILE_ID
    );
    if (profileId) {
      this.subs.sink = this.getProfileLocationGQL
        .watch(
          {
            profileId,
          },
          { fetchPolicy: 'network-only' }
        )
        .valueChanges.subscribe(({ data }) => {
          const { profile } = data;
          if (profile) {
            this.profileLocations = profile.locations;
          }
        });
    }
  }

  getCoordinatesAndUpdateMap() {
    let isUpdateMap = false;

    let coordinates: mapboxgl.Point = new mapboxgl.Point(0, 0);

    if (
      this.coordinates?.x &&
      this.location?.coordinates?.x &&
      this.coordinates.x !== this.location.coordinates.x
    ) {
      coordinates.x = this.location?.coordinates?.x;
      isUpdateMap = true;
    }
    if (
      this.coordinates?.y &&
      this.location?.coordinates?.y &&
      this.coordinates.y !== this.location.coordinates.y
    ) {
      coordinates.y = this.location?.coordinates?.y;
      isUpdateMap = true;
    }

    if (isUpdateMap) {
      this.updateMap();
    }

    return coordinates;
  }

  setControlValues() {
    if (this.location?.id) {
      this.selectedLocation = this.location;
      const country =
        this.location.country && getCountryListCode(this.location.country)
          ? this.location.country
          : null;

      this.locationForm.patchValue(
        {
          id: this.location.id,
          name:
            this.parentFormGroup?.controls.name?.value ?? this.location.name,
          streetAddress1: this.location.streetAddress1,
          streetAddress2: this.location.streetAddress2,
          zip: this.location.zip,
          phoneNumber: this.location.phoneNumber,
          country,
          region: this.location.region,
          city: this.location.city,
          coordinates: {
            x: this.location.coordinates?.x,
            y: this.location.coordinates?.y,
          },
        },
        {
          emitEvent: false, // fix for the loop
        }
      );

      let isUpdateMap = false;
      if (
        this.coordinates?.x &&
        this.location?.coordinates?.x &&
        this.coordinates.x !== this.location.coordinates.x
      ) {
        this.coordinates.x = this.location?.coordinates?.x;
        isUpdateMap = true;
      }
      if (
        this.coordinates?.y &&
        this.location?.coordinates?.y &&
        this.coordinates.y !== this.location.coordinates.y
      ) {
        this.coordinates.y = this.location?.coordinates?.y;
        isUpdateMap = true;
      }

      if (isUpdateMap) {
        this.updateMap();
      }

      // TOFIX: this is commented since couldn't think a reason
      // what this is for, if ever this caused problems fix this
      // later
      // this.locationForm.markAsPristine();
      // this.pristine.emit(this.locationForm.pristine);
    }
  }

  initMap() {
    this.mapSettings = {
      container: this.getMapContainer(),
      style: this.mapboxService.DarkStyle,
      center: [this.coordinates.x, this.coordinates.y],
      zoom: 15,
    };
    this.mapSettings.center = [this.coordinates.x, this.coordinates.y];
    this.mapSettings.container = this.getMapContainer();
    this.map = new mapboxgl.Map(this.mapSettings);
    this.marker.setLngLat(this.mapSettings.center).addTo(this.map);

    if (!this.showMapOnly && this.showLocationSearch) {
      // TODO: figure out what is geocoder2 for, why can't we just use geocoder1?
      // const geoCoderId = 'geocoder1';
      const geoCoderId = this.isCreate ? 'geocoder1' : 'geocoder2';
      const geoCoderElement = document.getElementById(geoCoderId);

      // console.log('geoCoderElement: ', geoCoderElement); // DEBUG

      if (geoCoderElement) {
        // Remove all children before adding geocoder
        while (geoCoderElement?.lastElementChild) {
          geoCoderElement.removeChild(geoCoderElement.lastElementChild);
        }
        this.geocoder.addTo(`#${geoCoderId}`);
      }
    }

    this.geocoderInputString =
      this.location?.streetAddress1 +
      ', ' +
      this.location?.zip +
      ', ' +
      this.location?.city +
      ',' +
      this.location?.country;

    this.marker.on('dragend', () => {
      const lngLat = this.marker.getLngLat();
      this.fineTunePosition(lngLat);
    });
    this.geocoder.on('result', (resultResponse: any) => {
      this.marker.remove();
      this.map.flyTo({
        center: resultResponse.result.center,
        speed: 1.5,
        essential: true,
        zoom: 15,
      });
      this.marker.setLngLat(resultResponse.result.center).addTo(this.map);
      this.marker.setDraggable(true);
      this.editLocation();
      this.mapboxResponse = resultResponse.result;
      this.setAddressToMapResponse();
    });
    this.map.resize();
  }

  getMapContainer(): string {
    return document.getElementById('map1') ? 'map2' : 'map1';
  }

  fineTunePosition(lngLat: mapboxgl.LngLat) {
    // this.mapboxResponse.center[0] = lngLat.lng;
    // this.mapboxResponse.center[1] = lngLat.lat;

    this.coordinates = this.mapboxService.lngLatToPoint(lngLat);
    /*
    next
    this.pristine.emit(false);
    this.editLocation();
    */
    // this.locationForm.patchValue({
    //  coordinates: this.mapCenterObject,
    // });
  }
  editOnTheMap() {
    const geocoderSearch =
      this.locationForm.controls.streetAddress1.value +
      ', ' +
      this.locationForm.controls.zip.value +
      ', ' +
      this.locationForm.controls.city.value +
      ',' +
      this.locationForm.controls.country.value;
    this.geocoder.query(geocoderSearch);

    this.editLocation();
  }
  editLocation() {
    this.marker.setDraggable(true);
    this.editingLocation = true;
  }
  cancelEditing() {
    this.editingLocation = false;
    this.geocoder.getPlaceholder();
    this.marker.setDraggable(false);
  }

  setAddressToMapResponse() {
    this.editLocation();

    this.coordinates = this.mapboxService.lngLatToPoint(
      this.marker.getLngLat()
    );

    this.locationForm.markAllAsTouched();
    this.locationForm.markAsDirty();

    this.locationForm.patchValue({
      name: this.locationForm.controls.name.value,
      streetAddress1: `${this.mapboxResponse.text} ${
        this.mapboxResponse.address ? this.mapboxResponse.address : ''
      }`,
      zip: this.filterResponse('postcode'),
      country: this.getCountry(),
      city: this.filterResponse('place'),
      region: this.filterResponse('region'),
      coordinates: this.coordinates,
    });

    // this.locationForm.markAllAsTouched;
    Object.keys(this.locationForm.controls).forEach((key) => {
      this.locationForm.get(key)?.markAsDirty();
    });

    this.updateMap();
  }

  filterResponse(id: string) {
    const value = _.filter(this.mapboxResponse.context, (item) => {
      return item.id.indexOf(id) > -1;
    });
    return value.length ? value[0].text : null;
  }

  submitForm(values: ILocationForm) {
    this.submitted.emit(values);
  }

  updateMap() {
    if (this.marker && this.map) {
      const center = new mapboxgl.LngLat(
        this.coordinates.x,
        this.coordinates.y
      );
      this.marker.remove();
      this.map.flyTo({
        center,
        speed: 1.5,
        essential: true,
        zoom: 15,
      });
      this.marker.setLngLat(center).addTo(this.map);
      this.map.resize();
    }
  }

  getCountry() {
    // mapbox returns only 'United States', however, the list is expecting the full name.
    const value = _.filter(this.mapboxResponse.context, (item) => {
      return item.id.indexOf('country') > -1;
    });

    if (value.length) {
      const matched = this.countries.filter(
        (country) => country?.code.toLowerCase() === value[0].short_code
      );
      return matched.length ? matched[0].name : null;
    }

    return null;
  }

  hasDuplicateName(locationName: string) {
    const locations = this.profileLocations.filter(
      (location) =>
        location.name?.toLowerCase().trim() ===
        locationName?.toLowerCase().trim()
    );
    return locations.length ? locations[0].id !== this.location?.id : false;
  }
}
