<app-form-dialog
  closeButtonText="{{ isCreateLocation ? 'CREATE' : 'SAVE' }}"
  [valid]="isFormValid"
  [pristine]="isFormPristine"
  [values]="values"
  dismissButtonText="CANCEL"
  headerText="{{ isCreateLocation ? 'CREATE_LOCATION' : 'EDIT_LOCATION' }}"
>
  <app-location-form
    (valid)="isFormValid = $event"
    (pristine)="isFormPristine = $event"
    (values)="values = $event"
    [isCreate]="isCreateLocation"
    [location]="values"
  ></app-location-form>
</app-form-dialog>
