import { CommonModule, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
} from '@angular/forms';
import { Maybe } from '@designage/gql';
import { ILocationForm } from '@desquare/interfaces';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { LocationSelectionComponent } from '../location-selection/location-selection.component';
import { LocationFormComponent } from '../location-form/location-form.component';

interface ITab {
  title: string;
  isCreateNewLocation: boolean;
}

const TAB_EXISTING = 'tabExisting';
const TAB_NEW = 'tabNew';

@Component({
  standalone: true,
  imports: [
    NgTemplateOutlet,
    FormsModule,
    TranslateModule,
    NgbNavModule,
    LocationSelectionComponent,
    LocationFormComponent,
  ],
  selector: 'app-location-tab-selection',
  template: `
    <nav
      ngbNav
      #nav="ngbNav"
      class="nav-tabs"
      [activeId]="selectedTab"
      (activeIdChange)="onTabChanged($event)"
    >
      <ng-container ngbNavItem="tabExisting" [destroyOnHide]="true">
        <a ngbNavLink>{{ 'ADD_TO_EXISTING_LOCATION' | translate }}</a>
        <ng-template ngbNavContent>
          <ng-container
            *ngTemplateOutlet="addToExistingLocation"
          ></ng-container>
        </ng-template>
      </ng-container>
      <ng-container ngbNavItem="tabNew" [destroyOnHide]="true">
        <a ngbNavLink>{{ 'CREATE_NEW_LOCATION' | translate }}</a>
        <ng-template ngbNavContent>
          <ng-container *ngTemplateOutlet="createNewLocation"></ng-container>
        </ng-template>
      </ng-container>
    </nav>

    <div [ngbNavOutlet]="nav"></div>

    <ng-template #addToExistingLocation>
      <app-location-selection
        class="d-block my-2"
        [profileId]="profileId"
        [selectedLocationId]="defaultLocationId"
        [isHideAddLocationButton]="true"
        [isHideEditLocationButton]="false"
        [parentFormGroup]="parentFormGroup"
        (editLocationClicked)="editLocation($event)"
        (selectionChange)="setLocation($event)"
        (createNewLocation)="showCreateLocationTab($event)"
      ></app-location-selection>

      <fieldset [disabled]="!isEditMode">
        @if(parentFormGroup){
        <app-location-form
          [showLocationName]="showLocationName"
          [showLocationSearch]="showLocationSearch"
          [location]="isNewLocation ? null : selectedLocation"
          (values)="updateLocation($event)"
          (valid)="isFormValid = $event"
        ></app-location-form>
        }
      </fieldset>
    </ng-template>

    <ng-template #createNewLocation>
      <fieldset [disabled]="!isEditMode">
        @if(parentFormGroup){
        <app-location-form
          [showLocationName]="showLocationName"
          [showLocationSearch]="showLocationSearch"
          [location]="isNewLocation ? null : selectedLocation"
          [locationName]="locationName"
          [parentFormGroup]="parentFormGroup"
          [isCreate]="true"
          (values)="updateLocation($event)"
          (valid)="isFormValid = $event"
        ></app-location-form>
        }
      </fieldset>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationTabSelectionComponent implements OnInit {
  @Input() profileId!: string;
  @Input() defaultLocationId!: string;
  @Input() parentFormGroup?: FormGroup;

  @Output() locationChanged = new EventEmitter<ILocationForm>();
  @Output() newLocation = new EventEmitter<boolean>();
  @Output() valid = new EventEmitter<boolean>();

  isFormValid = false;

  tabs: ITab[] = [
    { title: 'Add to existing location', isCreateNewLocation: false },
    { title: 'Create new location', isCreateNewLocation: true },
  ];

  selectedLocation!: Maybe<Location>;
  showLocationSearch!: boolean;
  showLocationName!: boolean;
  isEditMode!: boolean;
  isNewLocation!: boolean;
  selectedTab!: string;
  locationName!: string;

  locationTabFormControl: FormControl = new FormControl();

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.initVariables();
    this.initFormControl();
  }

  initVariables() {
    this.showLocationName = false;
    this.showLocationSearch = false;
    this.isEditMode = false;
    this.isNewLocation = false;
    this.selectedTab = TAB_EXISTING;
  }

  initFormControl() {
    const FORM_CONTROL_NAME = 'locationTab';

    this.locationTabFormControl =
      (this.parentFormGroup?.controls[FORM_CONTROL_NAME] as FormControl) ??
      this.formBuilder.control(this.selectedTab);

    this.selectedTab = this.locationTabFormControl.value;
    this.setVariablesDependingOnTab(this.selectedTab);

    if (this.parentFormGroup) {
      this.parentFormGroup?.addControl(
        FORM_CONTROL_NAME,
        this.locationTabFormControl
      );
    }
  }
  setLocation(selectedLocation: Location) {
    this.selectedLocation = selectedLocation;
  }

  setVariablesDependingOnTab(tab: string) {
    if (tab === TAB_NEW) {
      this.isEditMode = true;
      this.isNewLocation = true;
      this.showLocationName = true;
      this.showLocationSearch = true;
    } else {
      this.isEditMode = false;
      this.isNewLocation = false;
      this.showLocationName = false;
      this.showLocationSearch = false;
    }
  }

  onTabChanged(tab: string) {
    this.selectedTab = tab;

    this.setVariablesDependingOnTab(tab);

    this.locationTabFormControl.setValue(tab);

    // These are the form group names from the child components
    const LOCATION_SELECTION = 'locationSelection';
    const LOCATION = 'location';

    // clear both form groups on tab change
    this.parentFormGroup?.removeControl(LOCATION_SELECTION);
    this.parentFormGroup?.removeControl(LOCATION);
  }
  editLocation(isEdit: boolean) {
    if (isEdit) {
      this.isEditMode = true;
      this.showLocationSearch = true;
    }
  }

  updateLocation(location: ILocationForm) {
    this.valid.emit(this.isFormValid);
    this.locationChanged.emit(location);
  }

  showCreateLocationTab(locationName: string) {
    this.locationName = locationName;
    this.onTabChanged(TAB_NEW);
  }
}
