<app-form-dialog
  [valid]="
    !publishForm.pristine &&
    name.trim().toLowerCase() !== locationName.trim().toLowerCase()
  "
  [useDangerButton]="false"
  closeButtonText="SAVE"
  dismissButtonText="CANCEL"
  headerText="DUPLICATE_LOCATIONS"
  [values]="name"
>
  <form id="publishForm" [formGroup]="publishForm">
    <p class="danger">
      {{ 'DUPLICATE_LOCATION_NAME_FOUND' | translate }}
    </p>
    <p>{{ 'DUPLICATE_LOCATIONS' | translate }}:</p>
    <div class="row">
      <div class="col-12">
        <!-- <app-datatable
          [rows]="duplicateLocations"
          [columns]="columns"
          [externalPaging]="false"
          [useRowSelection]="true"
          [limit]="pageSize"
          [count]="total"
          [pageOffset]="page - 1"
          [datatableUID]="'app-duplicate-location-name-dialog'"
          (pageChange)="onPageChange($event)"
        ></app-datatable> -->
      </div>
    </div>
    <div class="form-group">
      <label>{{ 'LOCATION_NAME' | translate }}:</label>
      <input
        type="text"
        class="form-control form-control-dark"
        formControlName="name"
      />
    </div>
  </form>
</app-form-dialog>
