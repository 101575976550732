import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  inject,
  computed,
  ChangeDetectionStrategy,
  effect,
} from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  GetProfileLocationsGQL,
  ProfileLocationsFragment,
  Location,
} from '@designage/gql';
import { ToasterService, LocationService } from '@desquare/services';
import { CreateLocationDialogComponent } from '../create-location-dialog/create-location-dialog.component';
import { ILocationForm } from '@desquare/interfaces';
import { ApolloError } from '@apollo/client/errors';
import { SubSink } from 'subsink';
import {
  FormGroup,
  FormBuilder,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { DuplicateLocationNameDialogComponent } from '../duplicate-location-name-dialog/duplicate-location-name-dialog.component';
import { LoaderComponent } from '@desquare/components/common/src/loader/loader.component';
import { TranslateModule } from '@ngx-translate/core';
import { TypeaheadComponent } from '@desquare/components/common/src/typeahead/typeahead.component';
import { LocationsStore } from '@desquare/stores';
import { lastValueFrom } from 'rxjs';

/**
 * TODO: Refactor this component to make use of reactive forms.
 */
interface ITab {
  text: string;
}

@Component({
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    LoaderComponent,
    TypeaheadComponent,
  ],
  selector: 'app-location-selection',
  template: ` @if(loading()){
    <app-loader [message]="loaderMessage"></app-loader>
    } @else {
    <div [formGroup]="locationForm">
      <div class="row append-items-end">
        <div
          class="form-group"
          [class.col-12]="isHideAddLocationButton && isHideEditLocationButton"
          [class.col-8]="!isHideAddLocationButton || !isHideEditLocationButton"
        >
          <designage-typeahead
            [items]="locationNames()"
            [placeHolderText]="'SELECT_A_LOCATION'"
            [noMatchedItemText]="
              isHideAddLocationButton && isHideEditLocationButton
                ? ''
                : 'Create new location'
            "
            [control]="locationForm.controls.locationName"
            (selectItem)="onLocationChange($event)"
            (noMatchedItem)="onNoMatchedItem($event)"
          ></designage-typeahead>
        </div>
        <div class="col-4 d-flex form-group">
          @if(!isHideAddLocationButton){
          <button
            type="button"
            class="btn btn-outline-primary"
            (click)="openCreateLocationDialog('add')"
          >
            {{ 'ADD_LOCATION' | translate }}
          </button>
          } @if(!isHideEditLocationButton){
          <button
            type="button"
            class="btn btn-outline-primary"
            (click)="openCreateLocationDialog('edit')"
          >
            {{ 'EDIT_LOCATION' | translate }}
          </button>
          }
        </div>
      </div>
    </div>
    }`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationSelectionComponent implements OnInit, OnDestroy {
  locationsStore = inject(LocationsStore);
  private subs = new SubSink();

  @Input() profileId!: string;
  @Input() selectedLocationId?: string;
  @Input() isHideAddLocationButton!: boolean;
  @Input() isHideEditLocationButton!: boolean;

  @Input() parentFormGroup?: FormGroup;

  @Output() createdLocation = new EventEmitter<ProfileLocationsFragment>();
  @Output() selectionChange = new EventEmitter<ProfileLocationsFragment>();
  @Output() editLocationClicked = new EventEmitter<boolean>();
  @Output() createNewLocation = new EventEmitter<string>();

  tabs: ITab[] = [
    { text: 'Create new location' },
    { text: 'Add to existing location' },
  ];

  locations = this.locationsStore.locations;
  loading = computed(() => this.locationsStore.loading());
  isConfirmationOpen = false;
  loaderMessage!: string;
  locationForm!: FormGroup;
  duplicateLocations?: ProfileLocationsFragment[] = [];
  selectedLocation = computed(() =>
    this.locations().find((x) => x.id === this.selectedLocationId)
  );
  locationNames = computed(() => this.locations().map((x) => x.name));

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private toasterService: ToasterService,
    private getProfileLocationsGQL: GetProfileLocationsGQL,
    private locationService: LocationService
  ) {
    effect(() => {
      console.log('locations', this.locations());
    });
  }

  ngOnInit() {
    this.initForm();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  initForm() {
    const FORM_GROUP_NAME = 'locationSelection';
    const currentSelectedLocation = this.locations().find(
      (x) => x.id === this.selectedLocationId
    );
    // console.log('currentSelectedLocation: ', currentSelectedLocation);

    this.locationForm =
      (this.parentFormGroup?.controls[FORM_GROUP_NAME] as FormGroup) ??
      this.formBuilder.group({
        locationId: [this.selectedLocationId, Validators.required],
        locationName: [
          currentSelectedLocation?.name || this.selectedLocation()?.name || '',
        ],
      });

    // this.subs.sink = this.locationForm.controls.locationId.valueChanges.subscribe((value) => {
    //   const location = this.locations.find((x) => x.id === value);

    //   if (location) {
    //     console.log('location selection: ', location); // DEBUG
    //     this.locationForm.controls.locationName.setValue(location.name)
    //     this.selectionChange.emit(location);
    //   }
    // });

    // this.subs.sink = this.locationForm.controls.locationName.valueChanges.subscribe((value) => {
    //   if (!value) {
    //     this.locationForm.patchValue({ locationId: null });
    //   }
    // });

    if (this.parentFormGroup) {
      this.parentFormGroup.addControl(FORM_GROUP_NAME, this.locationForm);
    }
  }

  openCreateLocationDialog(action: string) {
    // Workaround to display the map in the dialog container.
    const geocoderContainer = document
      .getElementById('geocoder1')
      ?.hasChildNodes()
      ? document.getElementById('geocoder1')
      : document.getElementById('geocoder2');
    const mapContainer = document.getElementById('map1')?.hasChildNodes()
      ? document.getElementById('map1')
      : document.getElementById('map2');
    const geocoderParent = geocoderContainer?.parentNode;
    const mapParent = mapContainer?.parentNode;
    geocoderContainer?.remove();
    mapContainer?.remove();

    const modalRef = this.modalService.open(CreateLocationDialogComponent, {
      size: 'lg',
      backdrop: 'static',
    });
    if (action === 'edit') {
      modalRef.componentInstance.values = this.selectedLocation();
      modalRef.componentInstance.isCreateLocation = false;
    }

    modalRef.result
      .then((values: ILocationForm) => {
        if (action === 'add') {
          if (this.profileId) {
            this.checkDuplicateBeforeCreating(values);
          } else {
            this.createLocation(values);
          }
        } else {
          this.updateLocation(values);
        }
      })
      .catch(() => {})
      .finally(() => {
        if (geocoderContainer) geocoderParent?.appendChild(geocoderContainer);
        if (mapContainer) mapParent?.appendChild(mapContainer);
      });
  }

  checkDuplicateBeforeCreating(values: ILocationForm) {
    const profileId = values.profileId
      ? values.profileId
      : this.profileId
      ? this.profileId
      : '';
    const { name } = values;

    const duplicateExists = this.locationsStore
      .locations()
      .find((locations) => {
        return locations.name === name;
      });

    if (duplicateExists) {
      this.openDuplicateLocationNameDialog(values);
    } else {
      this.createLocation(values);
    }
  }

  createLocation(input: ILocationForm) {
    input.profileId = this.profileId ? this.profileId : null;

    this.loaderMessage = 'CREATING_LOCATION';

    // Remove id property before creation
    if ('id' in input) {
      delete input.id;
    }

    lastValueFrom(this.locationService.createLocation(input, this.profileId))
      .then(({ data }) => {
        if (data && data.createLocation.isSuccessful) {
          this.toasterService.success('CREATE_LOCATION_SUCCESS');
          if (data.createLocation.location) {
            this.selectedLocationId = data.createLocation.location.id;
            this.createdLocation.emit(data.createLocation.location);
            this.locationForm.patchValue({
              locationId: data.createLocation.location.id,
            });
            this.locationsStore.addLocation(
              data.createLocation.location as Location
            );
          }
        }
      })
      .catch((error: ApolloError) => {
        error.graphQLErrors.forEach((gqlError) => {
          console.error('createLocation', gqlError);
          this.toasterService.handleGqlError(gqlError);
        });
      });
  }

  updateLocation(input: ILocationForm) {
    input.id = this.selectedLocation()?.id;
    input.profileId = this.profileId ? this.profileId : null;

    this.loaderMessage = 'UPDATING_LOCATION';

    lastValueFrom(this.locationService.updateLocation(input, this.profileId))
      .then(({ data }) => {
        if (data && data.updateLocation.isSuccessful) {
          this.toasterService.success('UPDATE_LOCATION_SUCCESS');
          if (data.updateLocation.location) {
            this.selectedLocationId = data.updateLocation.location.id;
            this.locationsStore.updateLocation(
              data.updateLocation.location as Location
            );
          }
        }
      })
      .catch((error: ApolloError) => {
        error.graphQLErrors.forEach((gqlError) => {
          console.error('updateLocation', gqlError);
          this.toasterService.handleGqlError(gqlError);
        });
      });
  }

  openDuplicateLocationNameDialog(values: ILocationForm) {
    if (!this.isConfirmationOpen) {
      this.isConfirmationOpen = true;
      const modal = this.modalService.open(
        DuplicateLocationNameDialogComponent,
        { windowClass: 'app-modal-xl', backdrop: 'static' }
      );
      modal.componentInstance.duplicateLocations = this.duplicateLocations;
      modal.componentInstance.locationName = values.name;
      modal.result
        .then(() => {
          this.createLocation(values);
        })
        .catch(() => {})
        .finally(() => {
          this.isConfirmationOpen = false;
        });
    }
  }

  // getLocations() {
  //   if (this.profileId) {
  //     this.loading = true;
  //     this.loaderMessage = 'FETCHING_LOCATIONS';

  //     this.subs.sink = this.getProfileLocationsGQL
  //       .watch(
  //         {
  //           profileId: this.profileId,
  //         },
  //         {
  //           fetchPolicy: 'network-only',
  //         }
  //       )
  //       .valueChanges.subscribe({
  //         next: ({ data, loading }) => {
  //           this.loading = loading;
  //           const { profile } = data;

  //           if (profile) {
  //             if (this.selectedLocation) {
  //               this.locationForm.patchValue({
  //                 locationId: this.selectedLocation.id,
  //                 locationName: this.selectedLocation.name,
  //               });
  //               this.selectionChange.emit(this.selectedLocation);
  //             }
  //           }
  //         },
  //         error: (error: ApolloError) => {
  //           error.graphQLErrors.forEach((gqlError) => {
  //             console.error('getProfileLocations', gqlError);
  //             this.toasterService.handleGqlError(gqlError);
  //           });

  //           this.loading = false;
  //         },
  //       });
  //   }
  // }

  onLocationChange(locationName: string) {
    const location = this.locations().find((x) => x.name === locationName);
    this.selectedLocationId = this.locations().find(
      (x) => x.name === locationName
    )?.id;
    if (location) {
      this.locationForm.patchValue({
        locationId: this.selectedLocationId,
        locationName,
      });
      this.selectionChange.emit(location);
    }
  }

  editLocation() {
    this.editLocationClicked.emit(true);
  }

  onNoMatchedItem(locationName: string) {
    this.createNewLocation.emit(locationName);
  }
}
