import {
  signalStore,
  withState,
  withComputed,
  withMethods,
  patchState,
  withHooks,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { tapResponse } from '@ngrx/operators';
import { LocationService } from '@desquare/services';
import { Signal, computed, inject, signal } from '@angular/core';
import { distinctUntilChanged, pipe, switchMap, tap } from 'rxjs';
import { GraphQLFormattedError } from 'graphql';
import { Location } from '@designage/gql';

export interface ILocationsSignal {
  locations: Location[];
  loading: boolean;
  errors: GraphQLFormattedError<Record<string, any>>[] | null;
}

const initialState: ILocationsSignal = {
  locations: [],
  loading: true,
  errors: null,
};

export const LocationsStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),

  withMethods((store, locationService = inject(LocationService)) => ({
    getLocationsFromApi: rxMethod<void>(
      pipe(
        distinctUntilChanged(),
        switchMap(() => {
          return locationService.getProfileLocationsFromApi$().pipe(
            tap((res) => console.log('getLocationsFromApi', res)),
            tapResponse({
              next: ({ locations, errors, loading }) => {
                patchState(store, {
                  locations,
                  loading,
                  errors: errors ? [...errors] : [],
                });
              },
              error: console.error,
              finalize: () => patchState(store, { loading: false }),
            })
          );
        })
      )
    ),
    getLocation: (channelId: string): Signal<Location | undefined> => {
      const location = signal<Location | undefined>(
        store.locations().find((x) => x.id === channelId)
      );
      return location;
    },
    updateLocation: (location: Location) => {
      patchState(store, {
        locations: store.locations().map((x) => {
          if (x.id === location.id) {
            return { ...x, ...location };
          } else {
            return x;
          }
        }),
      });
    },
    addLocation: (location: Location) => {
      patchState(store, {
        locations: [...store.locations(), location],
      });
    },
    removeLocation: (playlistId: string) => {
      patchState(store, {
        locations: store.locations().filter((x) => x.id !== playlistId),
      });
    },
  })),
  withHooks((store) => ({
    onInit() {
      // Load products when the store is initialized
      store.getLocationsFromApi();
    },
  })),
  withComputed((state) => ({}))
);
