@if(showMapOnly){
<div id="map-container" class="map-container">
  <div id="map1" class="map"></div>
  <div id="map2" class="map"></div>
</div>
} @else {
<form
  [id]="formId"
  [formGroup]="locationForm"
  (submit)="submitForm($event, locationForm.value)"
>
  @if(showLocationName){
  <div class="row mt-2">
    <div class="col-12 form-group mb-3">
      <label class="form-label">{{ 'LOCATION_NAME' | translate }}*</label>
      @if(locationForm && !locationForm.controls['name'].pristine &&
      (locationForm.controls['name'].hasError('required') ||
      !locationForm.controls['name'].value)){
      <span class="error">
        {{ 'NAME_IS_REQUIRED' | translate }}
      </span>
      } @if(isDuplicateName){
      <span class="error">
        {{ 'DUPLICATE_LOCATION_NAME' | translate }}
      </span>
      }
      <div>
        <input
          #locationName
          type="text"
          name="name"
          class="form-control form-control-dark"
          formControlName="name"
          autocomplete="do-not-autofill"
        />
      </div>
    </div>
  </div>
  } @if(showLocationSearch){
  <div class="row">
    <div class="col-6 form-group mb-3">
      <label class="form-label">{{ 'FIND_LOCATION' | translate }}</label>
      <div id="geocoder-container">
        @if(isCreate){
        <div id="geocoder1" class="geocoder"></div>
        }
        <div id="geocoder2" class="geocoder"></div>
      </div>
    </div>
    <div class="col-3 d-flex form-group mb-3">
      <button
        class="mt-auto btn btn-block"
        (click)="setAddressToMapResponse()"
        [class.btn-outline-primary]="!editingLocation"
        [class.btn-primary]="editingLocation"
        [disabled]="!editingLocation"
      >
        {{ 'UPDATE_LOCATION_BUTTON' | translate }}
      </button>
    </div>
    <div class="col-3 d-flex form-group mb-3">
      @if(!editingLocation){
      <button
        class="mt-auto btn btn-block btn-primary"
        (click)="editOnTheMap()"
        [disabled]="editingLocation"
      >
        {{ 'EDIT_LOCATION_BUTTON' | translate }}
      </button>
      } @else {
      <button
        class="mt-auto btn btn-block btn-outline-danger"
        (click)="cancelEditing()"
      >
        {{ 'CANCEL' | translate }}
      </button>
      }
    </div>
  </div>
  }
  <div class="row">
    <div class="d-none d-sm-block col-8">
      <div id="map-container" class="map-container">
        <div #mapboxContainer id="map1" class="map"></div>
        <div id="map2" class="map"></div>
      </div>
    </div>
    <div class="col-12 col-sm-4">
      <div class="form-group mb-3">
        <label class="form-label">{{ 'STREET_ADDRESS' | translate }} 1*</label>
        @if(locationForm && !locationForm.controls['streetAddress1'].pristine &&
        (locationForm.controls['streetAddress1'].hasError('required') ||
        !locationForm.controls['streetAddress1'].value)){
        <span class="error">
          {{ 'STREET_ADDRESS_IS_REQUIRED' | translate }}
        </span>
        }
        <input
          type="text"
          name="streetAddress1"
          class="form-control form-control-dark"
          formControlName="streetAddress1"
          autocomplete="do-not-autofill"
        />
      </div>
      <div class="form-group mb-3">
        <label class="form-label">{{ 'STREET_ADDRESS' | translate }} 2</label>
        <input
          type="text"
          name="streetAddress2"
          class="form-control form-control-dark"
          formControlName="streetAddress2"
          autocomplete="do-not-autofill"
        />
      </div>
      <div class="form-group mb-3">
        <label class="form-label">{{ 'ZIP' | translate }}*</label>
        @if(locationForm && !locationForm.controls['zip'].pristine &&
        (locationForm.controls['zip'].hasError('required') ||
        !locationForm.controls['zip'].value)){
        <span class="error">
          {{ 'ZIP_IS_REQUIRED' | translate }}
        </span>
        }
        <input
          type="text"
          name="zip"
          class="form-control form-control-dark"
          formControlName="zip"
          autocomplete="do-not-autofill"
        />
      </div>
      <div class="form-group mb-3">
        <label class="form-label">{{ 'CITY' | translate }}*</label>
        @if(locationForm && !locationForm.controls['city'].pristine &&
        (locationForm.controls['city'].hasError('required') ||
        !locationForm.controls['city'].value)){
        <span class="error">
          {{ 'CITY_IS_REQUIRED' | translate }}
        </span>
        }
        <input
          type="text"
          name="city"
          class="form-control form-control-dark"
          formControlName="city"
          autocomplete="do-not-autofill"
        />
      </div>
      <div class="form-group mb-3">
        <label class="form-label">{{ 'COUNTRY' | translate }}*</label>
        @if(locationForm && !locationForm.controls['country'].pristine &&
        (locationForm.controls['country'].hasError('required') ||
        !locationForm.controls['country'].value)){
        <span class="error">
          {{ 'COUNTRY_IS_REQUIRED' | translate }}
        </span>
        }
        <select
          formControlName="country"
          class="form-control form-control-dark form-select form-select-dark"
        >
          <option value="null" disabled>{{ 'COUNTRY' | translate }}</option>
          @for(country of countries; track country.name){
          <option [ngValue]="country.name">
            {{ country.name | translate }}
          </option>
          }
        </select>
      </div>
      <div class="form-group mb-3">
        <label class="form-label">{{ 'PHONE_NUMBER' | translate }}</label>
        @if(locationForm && !locationForm.controls['phoneNumber'].pristine &&
        locationForm.controls['phoneNumber'].hasError('Mask error')){
        <span class="error">
          {{ 'PHONE_NUMBER_LENGTH_ERROR' | translate }}
        </span>
        }
        <input
          type="text"
          name="phoneNumber"
          mask="0000000099"
          class="form-control form-control-dark"
          formControlName="phoneNumber"
          autocomplete="do-not-autofill"
        />
      </div>
      <div class="form-group mb-3">
        <label class="form-label">{{ 'REGION' | translate }}</label>
        <input
          type="text"
          name="region"
          class="form-control form-control-dark"
          formControlName="region"
          autocomplete="do-not-autofill"
        />
      </div>
    </div>
  </div>
</form>
}
